.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 800px;
    background-color: #333;
}

.pinContainer {
    position: relative;
    z-index: 10;
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pinInput {
    background-color: #fff;
    border: 2px solid #ddd;
    padding: 20px;
    margin-bottom: 40px;
    width: 100%;
    text-align: center;
    font-size: 40px;
}

.keypad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.key {
    background-color: #444;
    color: #ddd;
    border: none;
    padding: 30px;
    font-size: 40px;
    border-radius: 10px;
    cursor: pointer;
}

.key:active {
    background-color: #555;
}
